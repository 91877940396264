
import request from '@/helpers/request';


class TKPSStockApi {



    list_stock = (data) => {
        return request({
            url: '/api/v1/stock/list',
            method: 'post',
            data
        });	
    }

    
    put_in_stock = (data) => {
        return request({
            url: '/api/v1/stock/in',
            method: 'post',
            data
        });	
    }

    put_out_stock = (data) => {
        return request({
            url: '/api/v1/stock/out',
            method: 'post',
            data
        });	
    }

    query_current  = (data) => {
        return request({
            url: '/api/v1/stock/current',
            method: 'post',
            data
        });	
    }


    query_stock_record_detail_query  = (data) => {
        return request({
            url: '/api/v1/stock/record/detail/query',
            method: 'post',
            data
        });	
    }


    approve_stock = (data) => {
        return request({
            url: '/api/v1/stock/produce/confirm',
            method: 'post',
            data
        });	
    }


    discard_stock = (data) => {
        return request({
            url: '/api/v1/stock/produce/discard',
            method: 'post',
            data
        });	
    }
    
}



let _api = null

export const useStockApi = () => {
    if (!_api) {
        _api = new TKPSStockApi();
    }
    return _api;
}
